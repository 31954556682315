import { h } from 'preact';

const LogoIcon = () => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 270.24 105.49" height="52px">
      <defs>
          <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
              <feOffset dx="2" dy="2" />
              <feGaussianBlur result="blur" stdDeviation="1" />
              <feFlood flood-color="#e46525" flood-opacity=".2" />
              <feComposite in2="blur" operator="in" />
              <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient id="linear-gradient" x1="39.72" y1="32.35" x2="35.48" y2="4.42" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#fed607" />
              <stop offset=".44" stop-color="#fec40e" />
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="44.86" y1="61.55" x2="48.99" y2="73.28" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#f68b28" />
              <stop offset="1" stop-color="#e46525" />
          </linearGradient>
          <linearGradient id="linear-gradient-3" x1="88.79" y1="1.26" x2="64.99" y2="61.03" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#f68b28" />
              <stop offset=".7" stop-color="#fec40e" />
          </linearGradient>
          <linearGradient id="linear-gradient-4" x1="25.75" y1="77.44" x2="-6.2" y2="4.85" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#fed607" />
              <stop offset=".7" stop-color="#f68b28" />
          </linearGradient>
          <linearGradient id="linear-gradient-5" x1="109.85" y1="76.35" x2="75.29" y2="44.51" xlinkHref="#linear-gradient-2" />
          <linearGradient id="linear-gradient-6" x1="89.37" y1="79.22" x2="74.8" y2="71.39" xlinkHref="#linear-gradient" />
          <linearGradient id="linear-gradient-7" x1="78.9" y1="96.44" x2="79.23" y2="68.4" xlinkHref="#linear-gradient-4" />
          <linearGradient id="linear-gradient-8" x1="84.7" y1="86.22" x2="96.68" y2="86.22" xlinkHref="#linear-gradient-2" />
          <linearGradient id="linear-gradient-9" x1="94.99" y1="62.26" x2="113.46" y2="66.07" xlinkHref="#linear-gradient-3" />
          <linearGradient id="linear-gradient-10" x1="100.55" y1="69.22" x2="110.55" y2="58.35" xlinkHref="#linear-gradient-2" />
          <linearGradient id="linear-gradient-11" x1="100.26" y1="72.06" x2="101.13" y2="62.39" xlinkHref="#linear-gradient-2" />
          <linearGradient id="linear-gradient-12" x1="54.23" y1="65.14" x2="72.26" y2="81" xlinkHref="#linear-gradient-3" />
          <linearGradient id="linear-gradient-13" x1="57.92" y1="82.44" x2="63.95" y2="65.41" xlinkHref="#linear-gradient-2" />
          <linearGradient id="linear-gradient-14" x1="70.67" y1="72.77" x2="65.54" y2="74.89" xlinkHref="#linear-gradient-2" />
          <linearGradient id="linear-gradient-15" x1="85.18" y1="95.5" x2="96.69" y2="107.45" xlinkHref="#linear-gradient-3" />
          <linearGradient id="linear-gradient-16" x1="97.08" y1="112.72" x2="90.02" y2="100.22" xlinkHref="#linear-gradient-2" />
          <linearGradient id="linear-gradient-17" x1="259.94" y1="114.6" x2="137.66" y2="-38.9" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#f68b28" />
              <stop offset="1" stop-color="#fec40e" />
          </linearGradient>
          <filter id="drop-shadow-2" filterUnits="userSpaceOnUse">
              <feOffset dx="2" dy="2" />
              <feGaussianBlur result="blur-2" stdDeviation="1" />
              <feFlood flood-color="#e46525" flood-opacity=".2" />
              <feComposite in2="blur-2" operator="in" />
              <feComposite in="SourceGraphic" />
          </filter>
      </defs>
      <g id="Layer_2-2">
          <g filter="url(#drop-shadow-1)">
              <polygon stroke-width='0px' fill="url(#linear-gradient)" points="54.61 0 73.19 18.42 57.87 46.62 27.38 58.19 4.08 31.95 10.6 20.54 54.61 0" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-2)" points="59.46 63.57 66.18 50.86 57.87 46.62 27.38 58.19 23.31 71.56 59.46 63.57" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-3)" points="107.74 40.75 93.89 42.38 75.8 38.96 66.18 50.86 57.87 46.62 73.19 18.42 54.61 0 73.51 9.62 97.96 14.02 107.74 40.75" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-4)" points="4.08 31.95 0 41.89 23.31 71.56 27.38 58.19 4.08 31.95" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-5)" points="107.74 40.75 93.89 42.38 75.8 38.96 66.18 50.86 59.46 63.57 68.06 66.67 86.55 66.67 107.74 40.75" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-6)" points="74.75 71.56 72.72 77.13 84.7 84.79 91.3 79.25 96.68 79.25 88.69 70.77 74.75 71.56" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-7)" points="72.72 77.13 72.72 83 85.35 93.19 84.7 84.79 72.72 77.13" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-8)" points="85.35 93.19 93.26 86.22 96.68 84.91 96.68 79.25 91.3 79.25 84.7 84.79 85.35 93.19" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-9)" points="96.68 63.83 96.68 67.42 102.3 66.28 105.38 59.6 100.43 59.6 96.68 63.83" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-10)" points="105.38 59.6 106.7 66.28 104.5 69.3 102.3 66.28 105.38 59.6" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-11)" points="104.5 69.3 98.62 70.15 96.68 67.42 102.3 66.28 104.5 69.3" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-12)" points="61.55 69.48 56.67 71.81 59.96 75.01 64.06 74.89 66.89 71.44 61.55 69.48" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-13)" points="56.67 71.81 57.88 76.4 60.71 78.27 64.41 78.24 64.06 74.89 59.96 75.01 56.67 71.81" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-14)" points="64.41 78.24 67.39 75.92 66.89 71.44 64.06 74.89 64.41 78.24" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-15)" points="89.72 96.48 86.95 99.14 86.95 102.4 92.22 103.16 93.85 99.44 89.72 96.48" />
              <polygon stroke-width='0px' fill="url(#linear-gradient-16)" points="86.95 102.4 88.96 105.05 93.31 105.49 95.08 101.57 93.85 99.44 92.22 103.16 86.95 102.4" />
              <path stroke-width='0px' fill="#fed607" d="M53.87,4.02c-1.36.22-41.13,18.91-41.13,18.91l-4.27,9.02,5.22,5.76L53.87,4.02Z" />
          </g>
          <path stroke-width='0px' fill="#2a2e34" d="M125.11,53.96h2.59l6.76,15.11,6.76-15.11h2.56v18.28h-2.4v-13.63l-6.08,13.63h-1.69l-6.1-13.66v13.66h-2.4v-18.28Z" />
          <path stroke-width='0px' fill="#2a2e34" d="M160.47,53.83h2.4v18.41h-2.4v-18.41Z" />
          <path stroke-width='0px' fill="#2a2e34" d="M194.04,53.81v18.44h-2.4l-9.67-14.66v14.66h-2.4v-18.44h2.4l9.67,14.64v-14.64h2.4Z" />
          <path stroke-width='0px' fill="#2a2e34" d="M226.24,63.08c0,5.71-3.72,9.17-9.77,9.17h-5.73v-18.41h5.73c6.05,0,9.77,3.54,9.77,9.25ZM216.46,70.27c4.81,0,7.32-2.7,7.32-7.19s-2.51-7.29-7.32-7.29h-3.33v14.48h3.33Z" />
          <path stroke-width='0px' fill="url(#linear-gradient-17)" filter="url(#drop-shadow-2)" d="M153.99,24.8h-10.14c-1.06-1.78-2.98-2.79-5.57-2.79-4.76,0-7.64,3.32-7.64,8.5,0,5.81,2.98,8.94,8.55,8.94,3.27,0,5.67-1.54,7.06-4.47h-9.47v-6.77h17.59v9.13c-2.02,4.95-7.26,10.43-16.1,10.43-10.38,0-17.2-7.11-17.2-17.25s6.77-17.2,17.15-17.2c8.55,0,14.41,4.42,15.76,11.48ZM179.74,47.87c-9.61,0-17.39-7.21-17.39-17.39s7.78-17.35,17.39-17.35,17.35,7.16,17.35,17.35-7.74,17.39-17.35,17.39ZM179.74,39.12c4.85,0,7.78-3.41,7.78-8.65s-2.93-8.7-7.78-8.7-7.78,3.36-7.78,8.7,2.88,8.65,7.78,8.65ZM215.71,13.66v26.67h10.52v7.21h-19.94V13.66h9.42ZM265.11,30.57c0,9.8-6.87,16.96-17.68,16.96h-13.41V13.66h13.41c10.81,0,17.68,6.97,17.68,16.91ZM246.71,39.22c5.43,0,8.84-3.08,8.84-8.65s-3.41-8.74-8.84-8.74h-3.27v17.39h3.27Z" />
      </g>
  </svg>
);

export default LogoIcon;